.aircraft-label-container.tail-number-indicators .aircraft-label {
  width: var(--aircraft-label-width-with-indicators);
}

.aircraft-label-container {
  background: var(--gantt-chart-tailnumbers-background);
  border-bottom: var(--gantt-row-line-weight) solid var(--gantt-chart-border-color);
  border-right: var(--gantt-row-line-weight) solid var(--gantt-chart-border-color);
  width: var(--aircraft-container-width);
  text-align: center;
  font-weight: 700;
  font-size: var(--aircraft-label-font-size);
  display: flex;
  justify-content: center;
}

.aircraft-label-container.tail-number-indicators {
  display: flex;
  justify-content: space-between;
  width: var(--aircraft-container-width-with-indicators);
  text-align: center;
  font-weight: 700;
  font-size: var(--aircraft-label-font-size);
  padding: var(--aircraft-container-padding);
}

.aircraft-label-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.1rem;
  height: var(--aircraft-label-icon-container-width);
  width: var(--aircraft-label-icon-container-width);
}

.multi-row-label-icon {
  display: flex;
  justify-content: space-between;
  margin-top: 2%;
  padding: 0.1rem;
}

.aircraft-label-icon {
  height: var(--aircraft-label-icon-container-width);
  width: var(--aircraft-label-icon-container-width);
}

.aircraft-label-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.1rem;
}
