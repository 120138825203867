.aircraft-header {
  font-weight: 800;
  font-size: 1.6rem;
  vertical-align: middle;
}
.aircraft-tab-container-row .MuiSkeleton-wave {
  margin-left: 1rem;
}
.aircraft-tab-container-row .no-mels-cdls-exist-aircraft {
  margin-left: 0.5rem;
}

.aircraft-tab-text.MEL,
.aircraft-tab-text.From,
.aircraft-title,
.aircraft-header {
  margin-left: 0.4rem;
}

.aircraft-title,
.aircraft-tab-text,
.aircraft-tab-date,
.aircraft-tab-time {
  font-size: 1.4rem;
  line-height: 2rem;
}
.aircraft-tab-text.MEL,
.aircraft-tab-date.Report {
  font-weight: 700;
}

.aircraft-title {
  font-weight: 800;
}

.aircraft-tab-text,
.aircraft-tab-date,
.aircraft-tab-time {
  font-weight: 400;
}

.aircraft-tab-date,
.aircraft-tab-time {
  color: #ffffff;
}

.aircraft-tab-time .gray-pad-left {
  padding-left: 0.3rem;
}
.aircraft-header.mx-messages-list {
  margin-top: 1.3rem;
}

@media (min-width: 500px) and (max-width: 668px) {
  .summary-aircraft-tab-container .aircraft-tab-container-row {
    width: 175%;
  }

  #summary-detail-pane-tabs-tabpane-Aircraft .summary-aircraft-tab-container {
    height: 74% !important;
    overflow-x: scroll !important;
  }

  .summary-aircraft-tab-container::-webkit-scrollbar {
    height: 1.2rem;
  }
}
@media (min-width: 668px) and (max-width: 1020px) {
  .summary-aircraft-tab-container .aircraft-tab-container-row {
    width: 150%;
  }

  #summary-detail-pane-tabs-tabpane-Aircraft .summary-aircraft-tab-container {
    height: -webkit-fill-available !important;
    overflow-x: scroll !important;
  }

  .summary-aircraft-tab-container::-webkit-scrollbar {
    height: 1.2rem;
  }
}
