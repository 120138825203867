.button {
  display: inline-block;
  border: 0;
  border-radius: 0.4rem;
  font-weight: bold;
  text-align: center;
  height: 4.4rem;
  min-width: 10.4rem;
}

.button:focus {
  outline: 0px none;
}

.button-primary {
  background-color: var(--primary-button-background);
  color: var(--primary-button-text);
}

.button-primary:hover {
  background-color: var(--button-hover-background);
  color: var(--primary-button-text);
}

.button-primary:active:hover {
  background-color: var(--primary-button-background);
  color: var(--primary-button-text);
}

.button-primary:disabled {
  background: var(--primary-button-disabled-background);
}

.button-secondary {
  background-color: var(--secondary-button-background);
  color: var(--secondary-button-text);
  border: 2px solid var(--primary-button-background);
}

.button-secondary:disabled {
  color: var(--secondary-button-disabled-text);
  background-color: #ffffff;
  border: 2px solid var(--secondary-button-disabled-background);
}
