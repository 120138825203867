.summary-crew-tab-container .MuiTableCell-head {
    color: var(--page-container-text);
    font-weight: bold;
    border-bottom: 1.5px solid var(--primary-border-color);
    padding: 0.8rem;
    font-family: var(--defaultFontFamily);
    font-size: var(--defaultFontSize);
}

.summary-crew-tab-container .MuiTableCell-body {
    color: var(--page-container-text);
    border-bottom: 0px;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    font-family: var(--defaultFontFamily);
    font-size: var(--defaultFontSize);
}

.summary-crew-tab-container .MuiTableRow-root:nth-of-type(odd) {
    background-color: var(--primary-background-color);
}

.summary-crew-tab-container  .dialog-hr-bottom {
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
}

.summary-crew-tab-container  .MuiGrid-spacing-xs-2 > .MuiGrid-item{
    padding-left: 1rem;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
}

.flightinfo-deadhead-wrapper {
    position: relative;
}

.collapse-region{
    margin-top: 30px;
}