.summary-flight-tab-container .flight-tab-component {
  margin-bottom: 1rem;
  margin-top: 0rem;
}
.summary-flight-tab-container .time-diff-label-green {
  color: var(--puck-phase-early-indicator);
}
.summary-flight-tab-container .time-diff-label-red {
  color: var(--puck-phase-delay-indicator);
}

/* media queries for devices with max-width of 1270px */
@media screen and (max-width: 1280px) {
  #summary-detail-pane-tabs-tabpane-Flight .summary-flight-tab-container {
    padding-top: 2.5rem;
  }
}

/* media queries for devices with max-width of 1020px */
@media screen and (max-width: 1020px) {
  #summary-detail-pane-tabs-tabpane-Flight .summary-flight-tab-container {
    padding-top: 2rem;
  }
  #summary-detail-pane-tabs-tabpane-Flight .MuiGrid-spacing-xs-1 > .MuiGrid-item {
    padding-bottom: 1rem;
  }
  #summary-detail-pane-tabs-tabpane-Flight .summary-flight-tab-container #flight-tab-header-container {
    margin-bottom: 0rem;
  }
}

/* media queries for devices with max-width of 620px */
@media screen and (max-width: 610px) {
  #summary-detail-pane-tabs-tabpane-Flight .summary-flight-tab-container #flight-tab-header-container {
    margin-bottom: 0rem;
  }
  #summary-detail-pane-tabs-tabpane-Flight .MuiGrid-spacing-xs-1 > .MuiGrid-item {
    padding-bottom: 1rem;
  }
  #summary-detail-pane-tabs-tabpane-Flight .summary-flight-tab-container {
    padding-top: 2rem;
  }
}
