.left-container,
.right-container {
  display: flex;
}

.flight-nav-bar-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  height: 6rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  background-color: var(--flight-nav-bar-background);
  position: relative;
  z-index: 1004;
}

.nav-hideshow {
  /* background-color: var(--primary-header-background); */
  margin-left: 2.6rem;
}
.nav-hideshow .nav-hideshow-icon {
  position: absolute;
  right: 0px;
  top: 0.7rem;
  background-color: var(--primary-header-background);
  height: 5rem;
  width: 30px;
}
.nav-hideshow .nav-hideshow-icon svg {
  margin-right: .7rem;
  margin-top: 1.5rem;
}


/* every icon should have same size */
.flight-nav-bar-item {
  width: 3.4rem;
  height: 3rem;
  text-align: center;
  box-shadow: var(--flight-nav-bar-item-shadow);
  cursor: pointer;
  background-color: var(--flight-nav-bar-icon-background);
}

.nav-icon {
  color: var(--flight-nav-bar-icon-fill);
}

.gantt-icon-container {
  border-top-left-radius: 0.6rem;
  border-bottom-left-radius: 0.6rem;
}

.flight-list-icon-container {
  border-top-right-radius: 0.6rem;
  border-bottom-right-radius: 0.6rem;
}

.gantt-icon-container,
.flight-list-icon-container {
  background-color: var(--gantt-flight-toggle-unselected-background);
  padding-top: 0.3rem;
}

.gantt-icon-container.selected,
.flight-list-icon-container.selected {
  background-color: var(--gantt-flight-toggle-selected-background);
}

.gantt-icon-container.selected g#Gant-Icon,
.flight-list-icon-container.selected g#List-Icon g {
  fill: var(--gantt-flight-toggle-selected-icon-fill);
}

.gantt-icon-container g#Gant-Icon,
.flight-list-icon-container g#List-Icon g {
  fill: var(--gantt-flight-toggle-unselected-icon-fill);
}

.nav-filter-button {
  margin-left: 2rem;
  border-radius: 0.6rem;
}

.flight-nav-bar-item.clear-filter {
  width: 8.8rem;
  color: var(--flight-nav-bar-icon-fill);
}
.flight-nav-bar-item.clear-filter.disabled {
  color: var(--gray);
  pointer-events: none;
}
.flight-nav-bar-item.disabled {
  pointer-events: none;
}
.nav-icon.disabled {
  color: var(--gray);
}

svg.nav-icon {
  font-size: 1.4rem;
}

.modal-open .MuiPopover-root {
  z-index: 10000 !important;
}

/* Hide canceled flight popover menu */
.MuiPopover-paper span.label {
  text-transform: uppercase;
  font-size: 1rem;
  margin-left: 1rem;
  font-weight: bold;
}

.MuiPopover-paper .checkbox-container.hide-canceled-flights {
  border: 0;
  display: flex;
  align-items: center;
  margin: 0px 2rem 1rem 1rem;
  cursor: pointer;
}

#hide-canceled-flights-popover .MuiPopover-paper {
  background-color: var(--dropdown-menu-background);
}

.MuiPopover-paper .checkbox-container.hide-canceled-flights .checkbox-icon {
  margin-right: 0.6rem !important;
}

.flight-list-icon-container.disabled g#List-Icon g {
  fill: var(--gray);
}

.flight-list-icon-container.disabled {
  pointer-events: none;
}

.flight-nav-bar-swap-mode-border {
  position: absolute;
  left: 0;
  top: 0;
  border-top: 0.6rem solid var(--gantt-flight-toggle-selected-background);
  border-left: 0.6rem solid var(--gantt-flight-toggle-selected-background);
  border-right: 0.6rem solid var(--gantt-flight-toggle-selected-background);
  width: 100%;
  height: 100%;
  z-index: 2000;
  pointer-events: none;
}

.overlapping-pucks-filter {
  padding: 0.2rem;
}