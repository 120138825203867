.high-contrast-theme .bottom-content,
.high-contrast-theme .gantt-content {
  background-color: var(--gantt-chart-background-high-contrast);
}

.gantt-chart-container {
  height: 100%;
}

.top-content {
  display: flex;
}

.bottom-content {
  display: flex;
  overflow: auto;
  position: relative;
  height: calc(100vh - var(--constant-gantt-bottom-content-height));
  background-color: var(--gantt-chart-background);
}
/* when header is hidden overriding height of bottom-content */
.page-content.hide-header .bottom-content {
  height: calc(100vh - var(--constant-gantt-bottom-content-height-hideheader));
}
/* when header is hidden overriding height of bottom-content */
.page-content.hide-header .summary-panel-mini .new-modal-filters .bottom-content {
  height: calc(100vh - var(--constant-gantt-bottom-content-height-hideheader-summary-mini));
}
/* when header and summary expanded is open is hidden overriding height of bottom content */
.page-content.hide-header .summary-panel-expanded .new-modal-filters .bottom-content {
  height: calc(100vh - var(--constant-gantt-bottom-content-height-hideheader-summary-expanded));
}
/* Below when Swapmode is ON */
/* when header is hidden swapmode on, overriding height of bottom-content */
.page-content.hide-header .swap-mode-active .bottom-content {
  height: calc(100vh - var(--constant-gantt-bottom-content-height-hideheader-swapmode));
  max-height: calc(100vh - var(--constant-gantt-bottom-content-height-hideheader-swapmode));
}
/* when header is hidden swapmode on, overriding height of bottom-content */
.page-content.hide-header .swap-mode-active.summary-panel-mini .new-modal-filters .bottom-content {
  height: calc(100vh - var(--constant-gantt-bottom-content-height-hideheader-summary-mini-swapmode));
  max-height: calc(100vh - var(--constant-gantt-bottom-content-height-hideheader-summary-mini-swapmode));
}
/* when header is hidden and summary expanded is open and swapmode is ON, overriding height of bottom content */
.page-content.hide-header .swap-mode-active.summary-panel-expanded .new-modal-filters .bottom-content {
  height: calc(100vh - var(--constant-gantt-bottom-content-height-hideheader-summary-expanded-swapmode));
  max-height: calc(100vh - var(--constant-gantt-bottom-content-height-hideheader-summary-expanded-swapmode));
}

.new-modal-filters .bottom-content {
  max-height: calc(100vh - var(--constant-gantt-bottom-content-height));
}
/* when header is hidden */
.page-content.hide-header .new-modal-filters .bottom-content {
  max-height: calc(100vh - var(--constant-gantt-bottom-content-height-hideheader));
}
.swap-mode-active .new-modal-filters .bottom-content {
  transition: height 0.3s ease-out;
  max-height: calc(100vh - 27.6rem);
}
.swap-mode-active.summary-panel-mini .new-modal-filters .bottom-content {
  max-height: calc(100vh - var(--constant-gantt-bottom-content-height-summary-mini-swapmode));
}
.swap-mode-active.summary-panel-expanded .new-modal-filters .bottom-content {
  max-height: calc(100vh - var(--constant-gantt-bottom-content-height-summary-expanded-swapmode));
}
/* not with swap mode */
.summary-panel-mini .new-modal-filters .bottom-content {
  max-height: calc(100vh - var(--constant-gantt-bottom-content-height-summary-mini));
}
.summary-panel-expanded .new-modal-filters .bottom-content {
  max-height: calc(100vh - var(--constant-gantt-bottom-content-height-summary-expanded));
}

.aircraft-axis {
  position: sticky;
  left: 0;
  z-index: 10;
  width: var(--aircraft-container-width);
  flex: 1 0 auto;
}

.aircraft-axis.tail-number-indicators {
  width: var(--aircraft-container-width-with-indicators);
}

.gantt-content {
  white-space: nowrap;
  position: relative;
  width: 100%;
}

#now-line {
  height: 100%;
  position: absolute;
  width: 2px;
  z-index: 3;
  background: var(--now-line-color);
  color: var(--now-line-color);
  border-left-color: var(--now-line-color);
}

.gantt-content {
  background-color: var(--gantt-chart-background);
}

.aircraft-row {
  border-bottom: var(--gantt-row-line-weight) solid var(--gantt-chart-border-color);
  position: relative;
  overflow: hidden;
}
.enhanced-aircraft-row {
  border-bottom: var(--gantt-row-line-weight) solid var(--gantt-chart-border-color);
  position: relative;
}

.crosshair-active:not(.swap-mode-active),
.crosshair-active:not(.swap-mode-active) {
  border-bottom: none;
}

.inner-gantt-content {
  z-index: 5;
}

@media (max-width: 773px) {
  .summary-panel-mini .new-modal-filters .bottom-content {
    max-height: calc(100vh - var(--constant-gantt-bottom-content-height-summary-mini-portrait));
  }
}

/* new */
.manage-operations-page-container .app-insights-tracking-gantt-chart {
  height: 100%;
}

.manage-operations-page-container .app-insights-tracking-gantt-chart .bottom-content {
  /* 8.4rem is height of gantt timeline */
  height: calc(100% - 8.4rem) !important;
}
